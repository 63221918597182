import UserIcon from "../images/user.png";
import { checkForImageUrl, checkForFileUrl } from "../utils";

const UserMessage = ({ text }: any) => {
  return (
    <div className="message-container">
      <div className="user-message-inner-container">
    { checkForFileUrl(text)? 
        (checkForImageUrl(text)?
          <img src={text} width={250}/>
          :<a href={text} target="_blank" className="bot-message">{text.substring(text.lastIndexOf('/')+1)}</a>
        )
        :<div className="user-message">{text}</div>
    }
        <img src={UserIcon} alt="user icon" className="user-chat-icon" />
      </div>
    </div>
  );
}

export default UserMessage;