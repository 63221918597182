import React from 'react';

interface dislikeprops {
  color: string;
}

function DislikeIcon(props: dislikeprops) {
  return (
      <svg
        width='15'
        height='13'
        viewBox='0 0 15 13'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M9.84375 0H3.51562C2.93438 0 2.4375 0.352857 2.22187 0.845L0.103125 5.75714C0.0374997 5.915 0 6.09143 0 6.26786V7.42857C0 7.67484 0.0987722 7.91103 0.274588 8.08517C0.450403 8.25931 0.68886 8.35714 0.9375 8.35714H5.79375L5.15625 11.31V11.5329C5.15625 11.8207 5.27812 12.0714 5.46562 12.2757L6.20625 13L10.8375 8.41286C11.0906 8.16214 11.25 7.80929 11.25 7.42857V1.39286C11.25 1.02345 11.1018 0.66917 10.8381 0.407958C10.5744 0.146747 10.2167 0 9.84375 0Z'
          fill={props.color}
          stroke='grey'
        />
        <path
          d='M15 3.5566C15 2.10518 13.8121 0.928571 12.3467 0.928571C12.2588 0.928571 12.1875 0.999168 12.1875 1.08625V7.27089C12.1875 7.35797 12.2588 7.42857 12.3467 7.42857C13.8121 7.42857 15 6.25196 15 4.80054V3.5566Z'
          fill={props.color}
          stroke='grey'
        />
      </svg>
  );
}

export default DislikeIcon;
