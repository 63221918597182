import React from 'react';

interface likeprops{
  color: string;
}

function LikeIcon(props: likeprops) {
  return (
      <svg
        width='15'
        height='13'
        viewBox='0 0 15 13'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M15 5.57143C15 5.32516 14.9012 5.08897 14.7254 4.91483C14.5496 4.74069 14.3111 4.64286 14.0625 4.64286H9.20625L9.84375 1.69V1.47643C9.84375 1.18857 9.72188 0.928571 9.53438 0.733571L8.79375 0L4.1625 4.58714C3.90937 4.83786 3.75 5.19071 3.75 5.57143V11.6071C3.75 11.9766 3.89816 12.3308 4.16188 12.592C4.4256 12.8533 4.78329 13 5.15625 13H11.4844C12.0656 13 12.5625 12.6471 12.7781 12.155L14.3731 8.45728L14.8969 7.24286C14.9625 7.085 15 6.90857 15 6.73214V5.57143Z'
          fill={props.color}
          stroke='grey'
        />
        <path
          d='M0 8.19946C0 6.74804 1.18792 5.57143 2.6533 5.57143C2.74122 5.57143 2.8125 5.64202 2.8125 5.72911V11.9137C2.8125 12.0008 2.74122 12.0714 2.6533 12.0714C1.18792 12.0714 0 10.8948 0 9.4434V8.19946Z'
          fill={props.color}
          stroke='grey'
        />
      </svg>
  );
}

export default LikeIcon;
