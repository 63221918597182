export const getBotResponse = async (index: any,learner:any,batch:any,jobpck:any,message: any, userName: any, userId: any, userEmail: any) => {
    let streamedData = ''
    let prompt_index;
    const access_token : any = process.env.REACT_APP_API_KEY;
    const api_endpoint :any = process.env.REACT_APP_API_ENDPOINT;
    if(index>=1){
        prompt_index = index;
    }
    const data = await fetch(api_endpoint+'jordy/', {
        method: 'post',
        body: JSON.stringify({
            "prompt_index": prompt_index,
            "prompt": message,
            "learner_id":learner,
            "batch_code":batch,
            "job_pack":jobpck
        }),
        headers: {
            'access_token': access_token,
            'Content-Type': 'application/json',
        },
    });
    const reader = data.body?.getReader();
    const decoder = new TextDecoder('utf-8');
    const readStream = async () => {
        if (!reader) {
            return;
        }
        let result;
        while (!(result = await reader.read()).done) {
            const chunk = decoder.decode(result.value, { stream: true });
            streamedData += chunk;
        }
    };
 
    await readStream().catch(err => {
        console.error('Error reading stream:', err);
    });
    console.log("streamedDataata====", streamedData);
    const resArrobj = [
        {
            "recipient_id": "Jordy0764407dadf75",
            "text": streamedData
        }
    ]
    return resArrobj;
};


export const getTodaysUserConversationDetails = async () => {
    const responseVar = JSON.parse('{"statusCode":"10000","message":"Previous conversation","data":{"messages":{}}}');
    return responseVar.data.messages;
};



export const checkForFile = (text: string) => {
    //Check if there is image file in message in format similar to [arrow.PNG](/user_uploads/44677/slZv5SkLV3boMDfJT-V-zzqL/arrow.PNG)
    return /([[a-z\-_0-9/:.]*\.(jpg|jpeg|png|gif|pdf|doc|docx|txt|xls|csv|xlsx|ppt|pptx)]([a-z\-_0-9/:.]*))/i.test(text);
}

export const checkForImageUrl = (text: string) => {
    //check for image url
    return /(https?:\/\/.*\.(?:png|jpg|jpeg))/i.test(text);
}

export const checkForFileUrl = (text: string) => {
    //check for file url
    return /(https?:\/\/.*\.(?:png|jpg|jpeg|gif|pdf|doc|docx|txt|xls|csv|xlsx|ppt|pptx))/i.test(text);
}

export const getBotDetails = (param: string) => {
    const details = document.getElementById("jordy")?.getAttribute('data-details');
    var fetchBotDetails = typeof details == 'string' && eval(details)
    if (typeof fetchBotDetails == 'function') {
        const botDetails = fetchBotDetails();
        return botDetails[param];
    }
    return null;
};