import { useEffect, useState } from "react";
import {checkForImageUrl, getBotDetails} from "../utils";

const ENDPOINT: any = getBotDetails("server-url") || process.env.REACT_APP_HUMAN_HANDOFF_ENDPOINT;

const ImageTrainer = ({ text, keyValue }: any) => {  
    const [fileUrl, setFileUrl] = useState('');
    const [fileName, setFileName] = useState('');

  useEffect(() => {
    const getImageURL = async () => {
        const imagePath=text.substring(text.indexOf("(") + 1, text.lastIndexOf(")"));
        const fileDetails=text.substring(text.indexOf("[") + 1, text.lastIndexOf("]"));
        setFileName(fileDetails);
        // const response = await getImageSentByExpert(imagePath, ENDPOINT);
        // setFileUrl(response?.data?.imageUrl);
    };

    getImageURL();
  }, []);

  const openFile = async () => {
    const imagePath = text.substring(text.indexOf("(") + 1, text.lastIndexOf(")"));
    //const response = await getImageSentByExpert(imagePath, ENDPOINT);
    //window.open(response?.data?.imageUrl, "_blank");
  };

  return (<>
    {checkForImageUrl(fileUrl) ? <img key={keyValue} src={fileUrl} alt="trainerimage" width="250px" /> : <div onClick={openFile} key={keyValue} className="bot-message file-style">{fileName}</div>}
  </>
  );
}

export default ImageTrainer;
