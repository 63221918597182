import React, { useState, useEffect, useRef, useContext } from "react";
import UserReactionContext from "../context/Reaction/userReactionContext";
import BotMessage from "./BotMessage";
import ConnectToHuman from "./ConnectToHumanPrompt";
import UserMessage from "./UserMessages";
import ScrollToBottom from 'react-scroll-to-bottom';

interface MessagesProps {
  messages: any;
  isMobileDevice: boolean;
  connectWithAssistant?: (connect: boolean, uniqueId: string) => void;
  uniqueId?: any;
  enableProgressiveDisplay?:Boolean;
}

const Messages = (props: MessagesProps) => {
  const { messages, isMobileDevice, connectWithAssistant, uniqueId, enableProgressiveDisplay } = props;
  const [contactToExpert, setContactExpert] = useState<any>()
  const el = useRef<any>(null);
  const handleConnectionWithAssistant = (connect: any, uniqueId: string) => {
    connectWithAssistant && connectWithAssistant(connect, uniqueId);
  }

  useEffect(() => {
    messages.map((item: any) => {
      if (item?.isConnectingToExpert) {
        setContactExpert(item);
      }
      if (item?.isallOurExpertsAreBusyWillGetBackToYouSoonText || item?.isConnectedWithExpertText) {
        setContactExpert(null)
      }
    })
  }, [messages])

  const mappingId = useContext(UserReactionContext)

  const getKey = (item: any) => {
    mappingId.setselectMessageId(item);
  }

  return (
    <>
      <div className="messages" style={isMobileDevice ? { height: '100%' } : {}}>
        <ScrollToBottom className={isMobileDevice ? 'scroll-bar-mobile' : 'scroll-bar'} >
          {
            messages.map((item: any, index: any) => (
              <React.Fragment key={item?.uniqueKey}>
                {
                  item?.isBotMessage && (
                    <BotMessage
                      isLastMessage={index === messages?.length - 1}
                      isPreviousMessage={item?.isPreviousMessage}
                      messages={messages}
                      uniqueKeyMap={item?.uniqueKey}
                      isConnectedToHuman={item?.isConnectedToHuman}
                      fetchMessage={async () => {
                        mappingId.setMappingId(item.uniqueKey)
                        return item.message;
                      }}
                      callingMes={getKey}
                      enableProgressiveDisplay={enableProgressiveDisplay}
                    />
                  )
                }
                {
                  (item?.isTrainerLeftTheChatText || item?.isallOurExpertsAreBusyWillGetBackToYouSoonText || item?.isallOurExpertsBusyPleaseTryAfterSomeTimeText) && (
                    <div
                      className="connect-to-expert-text"
                    >
                      {item.message}
                    </div>
                  )
                }
                {
                  item?.isConnectedWithExpertText && (
                    <div
                      className="connected-assitant-text"
                    >
                      {item.message}
                    </div>
                  )
                }
                {
                  item?.istrainerJoinedText && (
                    <div
                      className="trainer-name-text">
                      <span>{item.message}</span>
                    </div>
                  )
                }
                {
                  item?.isUserMessageText && (
                    <UserMessage
                      text={item.message}
                    />
                  )
                }
                {
                  item?.isConnectWithAssistant && (
                    <ConnectToHuman
                      uniqueId={uniqueId}
                      connectWithAssistant={handleConnectionWithAssistant}
                    />
                  )
                }
                {
                  item?.isConnectingToExpert && (
                    <div className="connect-to-expert-text">
                      {contactToExpert?.message}
                    </div>
                  )
                }
              </React.Fragment>
            ))
          }
        </ScrollToBottom>
        <div style={{ padding: '10px' }} id={"el"} ref={el} />
      </div>
      <div style={{ padding: isMobileDevice ? '30px' : '20px' }} />
    </>
  );
};

export default Messages;
