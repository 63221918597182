interface IntentDataProps {
  hoverState: boolean;
  intentDetails:any
}

const IntentData = (props: IntentDataProps) => {
    const { hoverState,intentDetails } = props;
    return (
        <div>
          {hoverState &&  (
            <div className="intent-data">
            <table>
              <thead>
                <tr>
                <th></th>
                <th>Intent Name</th>
                <th>Confidence</th>
                </tr>
              </thead>
              <tbody>
              {intentDetails["Top_2_intents"].map((intent: any,index: any)=><tr key={"intent"+index}><td><b>Intent {index+1}</b></td><td>{intent.name}</td><td>{intent.confidence}</td></tr>)}
              {intentDetails["Top_3_sub_intents"].map((subIntent: any,index: any)=><tr key={"sub-intent"+index}><td><b>Sub-intent {index+1}</b></td><td>{subIntent.intent_response_key}</td><td>{subIntent.confidence}</td></tr>)}
              </tbody>
            </table>
            </div>
          )}
        </div>
      );
}


export default IntentData;
