import React, { useState, useContext, useEffect } from "react";
import UserReactionContext from "../context/Reaction/userReactionContext";
import { getBotDetails } from "../utils";

const ENDPOINT: any = getBotDetails("server-url") || process.env.REACT_APP_HUMAN_HANDOFF_ENDPOINT;

const Input = ({ onSend, waitForHumanResponse, isConnectedToHuman, isMobileDevice }: any) => {
  const [text, setText] = useState("");
  const getReaction = useContext(UserReactionContext);
  const  { isTextLoading } = useContext(UserReactionContext);
  const { isResponseLoading } = useContext(UserReactionContext);
  
  useEffect(()=>{
    if(getReaction.feedbackMessage == 'dislike'){
      if(getReaction.selectMessageId == getReaction.mappingId){
      onSend('Retry', true);
      }
      getReaction.setFeedbackMessage('');
    }
  },[getReaction]);
   

  const handleInputChange = (e: any) => {
    setText(e.target.value);
  };

  const handleSend = (e: any) => {
    e.preventDefault();
    onSend(text);
    setText("");
  };

  const sendFile = async (e: any) => {
    e.preventDefault()
    let file = e.target.files[0];
    // const uploadedFileUrl = await getS3UrlOfImage(file, ENDPOINT);
    // onSend(uploadedFileUrl);
  }

  return (
    <div className="input" style={isMobileDevice ? { width: '100%', height: 'auto', position: 'fixed' } : {}}>
      <form onSubmit={handleSend} className="form-block">
        <input
          type="text"
          onChange={handleInputChange}
          value={text}
          placeholder="Enter your message here"
          disabled={waitForHumanResponse || isTextLoading || isResponseLoading}
        />
        {(!waitForHumanResponse && isConnectedToHuman) && (
          <>
            <input
              type="file"
              name="uploadFile"
              id="uploadFile"
              className="upload-file"
              onChange={(e) => sendFile(e)}
            />
            <img src="https://doubt-buster-images.s3.ap-south-1.amazonaws.com/uploadicon.png" alt="upload icon" className="upload-icon" onClick={(e) => { document.getElementById('uploadFile')?.click(); }} />
          </>
        )}
        <button disabled={waitForHumanResponse || !text.trim().length}>
          <img src="https://doubt-buster-images.s3.ap-south-1.amazonaws.com/arrow.png" alt="arrow icon" className="arrow-icon" />
        </button>
      </form>
    </div>
  );
};

export default Input;
