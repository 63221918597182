import { useState, useContext } from 'react';
import UserReactionContext from '../context/Reaction/userReactionContext';
import LikeIcon from './Likesvg';
import DislikeIcon from './Dislikesvg';
import React from 'react';

const reactions = {
  like: LikeIcon,
  dislike: DislikeIcon,
};
interface hoverStateProps {
  hoverState: boolean;
  onSubmitHandler: (reactionType: any) => void;
}

function ReactionHover(props: hoverStateProps) {
  const { onSubmitHandler, hoverState } = props;
  const [disabled, setdisabled] = useState(false);
  const selectEmojiState = useContext(UserReactionContext);
  const [name, setName] = useState('');

  function handleFeedback(name: string, Emoji: any) {
    setName(name);
    selectEmojiState.setFeedbackMessage(name);
    onSubmitHandler(name);
    setdisabled(true);
  }

  const  { isResponseLoading, isTextLoading } = useContext(UserReactionContext);
  return (
    <div style={{ position: 'relative' }}>
      {(hoverState && !isResponseLoading && !isTextLoading) && (
        <div className= 'hoverButtons'>
          {Object.entries(reactions).map(([name, Emoji]) => (
            <button
              style={{
                border: 'none',
                backgroundColor: '#dbf0ff',
                display: !disabled ? 'inline' : 'none',
                paddingTop: '10px',
              }}
              key={name}
              disabled={disabled}
              onClick={() => handleFeedback(name, Emoji)}
            >
              {<Emoji color='transparent' />}
            </button>
          ))}
        </div>
      )}
      <span>
        <div className='selectedReaction'>
          {name === 'like' && <LikeIcon color='#006BB6' />}
          {name === 'dislike' && <DislikeIcon color='#FF999D' />}
        </div>
      </span>
    </div>
  );
}

export default React.memo(ReactionHover);
