import { useState, useEffect, useRef, memo, useContext } from 'react';
import UserReactionContext from '../context/Reaction/userReactionContext';
import React from 'react';

interface userProgressiveProps {
  line: string;
  isLastMessage: Boolean;
  isPreviousMessage: any;
}

const UseProgressiveDisplay = (props: userProgressiveProps) => {
  const { line, isLastMessage, isPreviousMessage } = props;
  const [displayLines, setDisplayLines] = useState<string[]>([]);
  const { setTextLoading } = useContext(UserReactionContext);
  const loadedRef = useRef(false);
  const initialRender = useRef(true);

  const MIN_DELAY = 5;
  const MAX_DELAY = 25;
  const delay = Math.max(MIN_DELAY, Math.min(MAX_DELAY, Math.floor(line.length * 10)));

  useEffect(() => {
    setTextLoading(true);
    if (isLastMessage && !isPreviousMessage) {
      let i = 0;
      const interval = setInterval(() => {
        const partialLine = line.substring(0, i);
        const lines = partialLine.split('\n');
        setDisplayLines(lines);
        i++;
        if (i > line.length) {
          clearInterval(interval);
          setTextLoading(false);
          loadedRef.current = true;
        }
      }, delay);
      return () => clearInterval(interval);
    } else {
      const lines = line.split('\n');
      setDisplayLines(lines);
      setTextLoading(false);
      loadedRef.current = true;
    }
  }, [delay]);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }
  }, [line, loadedRef]);

  return (
    <>
      {displayLines.map((displayLine, index) => (
        <React.Fragment key={index}>
          {displayLine}
          {index < displayLines.length - 1 && <br />}
        </React.Fragment>
      ))}
    </>
  );
};

export default memo(UseProgressiveDisplay);
