import React, { useState, useEffect, useRef, useContext } from "react";
import styled from "styled-components";
import Input from "./Input";
import Messages from "./Messages";
import socketIOClient from "socket.io-client";
import { generateKey } from "../general";
import { allOurExpertsAreBusyWillGetBackToYouSoonText, connectedWithExpertText, connectingToExpertText, trainerLeftTheChat, trainerJoinedText, byeText, allOurExpertsBusyPleaseTryAfterSomeTimeText, pleaseContactYourTrainer, triggerMessageText, generateInitialText, iCanHelpWithYourRelatedQuestionsText } from "../constants";
import { getBotDetails, getBotResponse, getTodaysUserConversationDetails } from "../utils"
import { ChatBotModes } from "../config";

//const ENDPOINT: any = getBotDetails("server-url") || process.env.REACT_APP_HUMAN_HANDOFF_ENDPOINT;
const connectedWithAssistantList: any = [];

let userName = getBotDetails("username") || new URL(window.location.href).searchParams.get('username') || 'Jordy' + generateKey() // If there is no username we will point it to jordy ;
let userId = getBotDetails("userId") || new URL(window.location.href).searchParams.get('userId');
const jobPackTechnology = getBotDetails("jobpackTechnology") || new URL(window.location.href).searchParams.get('jobpackTechnology') || 'generic';
let shouldDisplayTrainerName = getBotDetails("shouldDisplayTrainerName") || new URL(window.location.href).searchParams.get('shouldDisplayTrainerName') || false;
let initialConnectionTimeout = getBotDetails("initialConnectionTimeout") || new URL(window.location.href).searchParams.get('initialConnectionTimeout') || 120000; // 2 mins
const isHandoffEnabled = getBotDetails("isHandoffEnabled") || true;
const enableProgressiveDisplay = getBotDetails("enableProgressiveDisplay") || new URLSearchParams(window.location.search).get('enableProgressiveDisplay') === 'true';
const userEmail = getBotDetails("userEmail") || "sneha.bushetty@zessta.com"
initialConnectionTimeout = Math.floor(initialConnectionTimeout);
shouldDisplayTrainerName = shouldDisplayTrainerName === 'true';
const ChatbotDiv = styled.div<{ isMobileDevice: boolean }>`
  font-family: 'Roboto', sans-serif;
  border-radius: 10px;
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.15);
  background: #FFFFFF;
  text-align: center;
  display: flex;
  flex-flow: column;
  overflow: hidden;
  width: ${props => !props.isMobileDevice ? '360px' : '100%'};
  height:${props => !props.isMobileDevice ? '500px' : '100%'};
  left: 50px;
  position: ${props => !props.isMobileDevice && 'absolute'};
  bottom: 20px
`;

interface ChatBotProps {
  handleWidget: (showWidget: boolean) => void;
  showWidget: boolean;
  mode: string;
  isMobileDevice: boolean;
}

const Chatbot = (props: ChatBotProps) => {
  const { handleWidget, showWidget, mode, isMobileDevice } = props;
  const [messages, setMessages] = useState<any>([]);
  const [isConnectedToHuman, setIsConnectedToHuman] = useState(false);
  const [waitForHumanResponse, setWaitForHumanResponse] = useState(false);
  const [inputMessages, setInputMessages] = useState<any>([]);
  const [connectedToExpert, setIsConnectedToExpert] = useState(false);
  const waitForMessageRef = useRef();
  const waitForContinousMessageRef = useRef();
  const [handoffSocket, setHandoffSocket] = useState<any>('');
  const [jobPackCode, setJobPackCode] = useState('java');
  const [isTrainerTypedBye, setIsTrainerTypedBye] = useState(false);
  const [isAlreadyConnectedToTrainer, setIsAlreadyConnectedToLearner] = useState(false);
  const [isMessageSet, setIsMessageSet] = useState(false);
  const [trainerName, setTrainerName] = useState('');
  const [isTrainerConnectedOnce, setIsTrainerConnectedOnce] = useState(false);
  const [uniqueId, setUniqueId] = useState('');
  const [retryText, setRetryText] = useState('');
  const [extraInfoMessages, setExtraInfoMessages] = useState({text: ''});
  var Arr:Array<any>;
  var ind:any;
  var lnr:any;
  var btc:any;
  var jpk:any;
 
  useEffect(() => {
    if (!showWidget && messages.length >= 2) {
      const tempMessages = [...messages];
      tempMessages[messages.length - 1].isPreviousMessage = true;
      setMessages(tempMessages);
    }

  }, [showWidget])

  useEffect(() => {
    const assignJobpackCode = async () => {
      let jobpack = getBotDetails("jobpack");

      if (!jobpack) {
        jobpack = new URL(window.location.href).searchParams.get('jobpack');
      }

      if (jobpack === null) jobpack = "java";
      setJobPackCode(jobpack);
    };

    assignJobpackCode();
  }, []);

  useEffect(() => {
    async function loadWelcomeMessage() {
      const prevMsgs = await getTodaysUserConversationDetails();
      const displayUserName = JSON.stringify(prevMsgs) === '{}' ? userName : prevMsgs?.userName;
      const tempMessages: any = [];
      if (mode === ChatBotModes.directToTrainer) {
        setIsMessageSet(true);
        tempMessages.push({
          isBotMessage: true,
          uniqueKey: generateKey(),
          isConnectedToHuman,
          message: [{ text: generateInitialText(displayUserName) }]
        },
          {
            isConnectingToExpert: true,
            uniqueKey: generateKey(),
            message: connectingToExpertText
          }
        )
      } else {
        tempMessages.push({
          isBotMessage: true,
          uniqueKey: generateKey(),
          isConnectedToHuman,
          message: [{ text: generateInitialText(displayUserName) }]
        },
          // {
          //   isBotMessage: true,
          //   uniqueKey: generateKey(),
          //   isConnectedToHuman,
          //   message: [{ text: iCanHelpWithYourRelatedQuestionsText(jobPackTechnology) }]
          // }
        )
      }

      setMessages((messages: any) => [...messages, ...tempMessages]);
      let prevMsgsDisplay = prevMsgs?.responses?.map((messageObject: {
        intentData: any; isBot: boolean; isTrainer: boolean; message: any; custom: any; image: any
      } | null) => {
        if (messageObject != null) {
          if (messageObject?.isBot === true || messageObject?.isTrainer == true) {
            const customMessage = () => {
              if (messageObject.message.custom || messageObject.message.image) {
                return messageObject.message;
              } else if (messageObject.message) {
                return { text: messageObject.message, intentData: messageObject.intentData }
              }
            }
            return {
              isBotMessage: true,
              isPreviousMessage: true,
              isConnectedToHuman: messageObject.isTrainer,
              uniqueKey: generateKey(),
              message: [customMessage()]
            }
          }
          else {
            return {
              isUserMessageText: true,
              isPreviousMessage: true,
              uniqueKey: generateKey(),
              message: messageObject?.message
            }
          }
        }
      })

      if (prevMsgsDisplay) {
        setMessages((messages: any) => [...messages, ...prevMsgsDisplay]);
      }
    }
    loadWelcomeMessage();
  }, []);

  useEffect(() => {
    if (isAlreadyConnectedToTrainer) {
      const temp = {
        isConnectingToExpert: true,
        uniqueKey: generateKey(),
        message: connectingToExpertText
      }

      setMessages((messages: any) => [...messages, temp])
    }
  }, [isAlreadyConnectedToTrainer]);

  useEffect(() => {
    if (mode === ChatBotModes.directToTrainer && showWidget && !isConnectedToHuman && !isTrainerConnectedOnce) {
      setIsConnectedToHuman(true);
      setWaitForHumanResponse(true);
    }

  }, [showWidget]);

  useEffect(() => {
    if (extraInfoMessages?.text) {
      const MIN_DELAY = 5;
      const MAX_DELAY = 25;
      const delay = Math.max(MIN_DELAY, Math.min(MAX_DELAY, Math.floor(messages[messages.length - 1]?.message[0].text?.length * 10)));
      setTimeout(() => {
        // setResponseLoading(true);
        setMessages((prevArray: any) => [...prevArray, {
          isBotMessage: true,
          uniqueKey: generateKey(),
          isConnectedToHuman,
          message: [{ text: extraInfoMessages.text }]
        }]);
      }, messages[messages.length - 1]?.message[0].text?.length * delay)

    }
  }, [extraInfoMessages])

  useEffect(() => {
    const index = messages.findIndex((item: any) => item?.message?.isLoading === true);
    const updateMessages = async () => {
      const response = await loadMessages(retryText ? retryText : messages[index].message.text);
      if (response[0]?.text?.includes(triggerMessageText)) {
        triggerMessage();
        setUniqueId(generateKey());
      }
if(enableProgressiveDisplay){
      if (response.length > 1) {
        setMessages((prevVal: any) => {
          prevVal[index].message = [response[0]];
          return prevVal;
        });
        setExtraInfoMessages(response[1]);
      } else {
        setMessages((prevVal: any) => {
          prevVal[index].message = response;
          return prevVal;
        });
      }
    }
    else {
      setMessages((prevVal: any) => {
        prevVal[index].message = response;
        return prevVal;
      });
    }
    }
    if (index >= 0) {
      updateMessages();
    }
  }, [messages]);

  useEffect(() => {
    if (handoffSocket) {
      // Remove spaces in username
      handoffSocket.on('connect', () => {
        if (inputMessages.length > 2) {
          inputMessages.splice(inputMessages.length - 2, 2);
        }
        const initialMessage = {
          messages: inputMessages,
          jobPackCode,
          jobPackTechnology,
          userName: userName,
          userId: userId,
          userEmail: userEmail,
          initialMessage: true
        }
        handoffSocket.emit('data', initialMessage);
        waitForMessage(initialConnectionTimeout);
      });

      handoffSocket.on('data', (data: any) => {
        setIsTrainerConnectedOnce(true);
        !trainerName && setTrainerName(data.trainerName);
        if (data?.message?.toLowerCase().includes(byeText)) {
          setIsTrainerTypedBye(true);
        }
        !connectedToExpert && clearTimeout(waitForMessageRef.current);
        isAlreadyConnectedToTrainer && setIsAlreadyConnectedToLearner(false);
        clearTimeout(waitForContinousMessageRef.current);
        waitForcontinousMessage();
        const tempMessages: any = [];
        if (!connectedToExpert) {
          tempMessages.push({
            isConnectedWithExpertText: true,
            uniqueKey: generateKey(),
            message: connectedWithExpertText
          });
        }

        if (waitForHumanResponse) {
          tempMessages.push({
            istrainerJoinedText: true,
            uniqueKey: generateKey(),
            message: `${shouldDisplayTrainerName ? trainerName : 'Expert'} ${trainerJoinedText}`
          });
        }

        tempMessages.push({
          isBotMessage: true,
          isConnectedToHuman,
          uniqueKey: generateKey(),
          message: [{ text: data.message }]
        });

        setMessages((messages: any) => [...messages, ...tempMessages]);
        waitForHumanResponse && setWaitForHumanResponse(false);
        !connectedToExpert && setIsConnectedToExpert(true);
      });

      return () => handoffSocket.removeListener('data');
    }
  }, [handoffSocket, connectedToExpert]);

  const connectWithAssistant = (connect: any, uniqueId: string) => {
    if (!connectedWithAssistantList.includes(uniqueId)) {
      const tempMessages: any = [];
      if (connect) {
        setIsConnectedToHuman(true);
        setWaitForHumanResponse(true);
      }
      connectedWithAssistantList.push(uniqueId);
      tempMessages.push({
        isUserMessageText: true,
        uniqueKey: generateKey(),
        message: [connect ? 'Yes' : 'No']
      });

      if (connect) {
        tempMessages.push(
          {
            isConnectingToExpert: true,
            uniqueKey: generateKey(),
            message: connectingToExpertText
          }
        )
      }

      setMessages((messages: any) => [...messages, ...tempMessages]);
    }
  }

  useEffect(() => {
    if (isMessageSet && (mode === ChatBotModes.regular) && isHandoffEnabled) {
      const temp = {
        isConnectWithAssistant: true,
        uniqueKey: generateKey(),
      };
      setMessages((messages: any) => [...messages, temp]);
    } else if (isMessageSet && (mode === ChatBotModes.regular) && !isHandoffEnabled) {
      const temp = {
        isBotMessage: true,
        isConnectedToHuman,
        uniqueKey: generateKey(),
        message: [{ text: pleaseContactYourTrainer }]
      };

      setMessages((messages: any) => [...messages, temp]);
    }
  }, [isMessageSet]);

  useEffect(() => {
    if (isConnectedToHuman) {
      //setHandoffSocket(socketIOClient(ENDPOINT));
    }
  }, [isConnectedToHuman]);

  const waitForcontinousMessage = () => {
    waitForContinousMessageRef.current = setTimeout(() => {
      const temp = {
        isTrainerLeftTheChatText: true,
        uniqueKey: generateKey(),
        message: `${shouldDisplayTrainerName ? trainerName : 'Expert'} ${trainerLeftTheChat}`
      };

      setMessages((messages: any) => [...messages, temp]);
      setIsConnectedToHuman(false);
      setIsConnectedToExpert(false);
      setWaitForHumanResponse(false);
      setInputMessages([]);
      setTrainerName('');
      handoffSocket.disconnect();
    }, 300000) as any; // 5 minutes
  };

  useEffect(() => {
    if (isTrainerTypedBye) {
      waitForMessageRef.current = setTimeout(() => {
        const temp = {
          isTrainerLeftTheChatText: true,
          uniqueKey: generateKey(),
          message: `${shouldDisplayTrainerName ? trainerName : 'Expert'} ${trainerLeftTheChat}`
        }

        setMessages((messages: any) => [...messages, temp])
        handoffSocket.disconnect();
        setIsConnectedToHuman(false);
        setIsConnectedToExpert(false);
        setInputMessages([]);
        clearTimeout(waitForContinousMessageRef.current);
        setIsTrainerTypedBye(false);
        setWaitForHumanResponse(false);
        setTrainerName('');
      }, 15000) as any;
    }
  }, [isTrainerTypedBye])

  const waitForMessage = (timeout: any) => {
    waitForMessageRef.current = setTimeout(() => {
      if (mode === ChatBotModes.directToTrainer) {
        const temp = {
          isallOurExpertsAreBusyWillGetBackToYouSoonText: true,
          uniqueKey: generateKey(),
          message: allOurExpertsAreBusyWillGetBackToYouSoonText
        }

        setMessages((messages: any) => [...messages, temp]);
        setWaitForHumanResponse(false);
      } else {
        handoffSocket.disconnect();
        const temp = {
          isallOurExpertsBusyPleaseTryAfterSomeTimeText: true,
          uniqueKey: generateKey(),
          message: allOurExpertsBusyPleaseTryAfterSomeTimeText
        }
        setMessages((messages: any) => [...messages, temp]);
        setIsConnectedToHuman(false);
        setIsConnectedToExpert(false);
        setWaitForHumanResponse(false);
      }
    }, timeout) as any;
  };

  const setConversion = (text: string) => {
    if (inputMessages.length < 7) {
      setInputMessages((messages: any) => [...messages, text])
    } else {
      setInputMessages((messages: any) => [...messages.slice(1), text]);
    }
  }

  const loadMessages = async (text: string) => {
    let response: any;
    //const cu = window.location.href+'?prompt_index=1&learner_id=madhub&batch_code=sample_batch_01&job_pack=jobpack01v';
    const cu = window.location.href;
    Arr= cu.split("&");
    //console.log('arr'+Arr);
    ind = Arr[0].split("=")[1]
    lnr = Arr[1]?.split("=")[1];
    btc = Arr[2]?.split("=")[1];
    jpk = Arr[3]?.split("=")[1];

    //console.log('ind',ind,lnr,btc,jpk);

    if (!isConnectedToHuman && mode !== ChatBotModes.directToTrainer) {
      response = await getBotResponse(ind,lnr,btc,jpk, text, userName, userId, userEmail);
      setConversion(`Bot:${response[0]?.text}`)
    }
    return response;
  };

  const triggerMessage = () => {
    setIsMessageSet(true);
  }

  const send = async (text: any, retryText?: any) => {
    if (retryText) {
      setRetryText('Wrong Answer')
    }
    else {
      setRetryText('');
    }
    if (!isConnectedToHuman && mode === ChatBotModes.directToTrainer) {
      setIsConnectedToHuman(true);
      setIsAlreadyConnectedToLearner(true);
      setWaitForHumanResponse(true);
    }

    if (isTrainerTypedBye) {
      clearTimeout(waitForMessageRef.current);
      setIsTrainerTypedBye(false);
    }

    if (isConnectedToHuman && !isTrainerTypedBye) {
      clearTimeout(waitForContinousMessageRef.current);
      waitForcontinousMessage();
    }

    if (!trainerName && waitForContinousMessageRef.current) {
      clearTimeout(waitForContinousMessageRef.current);
    }

    if (isConnectedToHuman) {
      handoffSocket.emit('data', { userName: userName, message: text, userId: userId, jobPackTechnology: jobPackTechnology, userEmail: userEmail });
    }
    isAlreadyConnectedToTrainer && setIsAlreadyConnectedToLearner(false);
    const userText = `User:${text}`
    setConversion(userText);
    setIsMessageSet(false);
    const tempMessages: any = [];
    tempMessages.push({
      isUserMessageText: true,
      uniqueKey: generateKey(),
      message: text
    });

    if (!isConnectedToHuman && mode === ChatBotModes.regular) {
      tempMessages.push({
        isBotMessage: true,
        isConnectedToHuman,
        uniqueKey: generateKey(),
        message: {
          isLoading: true,
          isConnectedToHuman,
          text: text
        }
      });
    };

    setMessages((messages: any) => [...messages, ...tempMessages]);
  };
  const jordyMinimizePosition = getBotDetails("style-jordy-icon");
  const chatbotWidth = getBotDetails("chatbot-width");
  const chatbotHeight = getBotDetails("chatbot-height");
  return (
    <>
      {
        showWidget === true ?
          (
            <>
              {
                !isMobileDevice && (
                  <div>
                    {/* <img src="https://doubt-buster-images.s3.ap-south-1.amazonaws.com/crossbackground.png" alt="jordy icon" className="no-image" onClick={() => handleWidget(false)} />
                    <img src="https://doubt-buster-images.s3.ap-south-1.amazonaws.com/crossmark.png" alt="jordy icon" className="cross-mark" onClick={() => handleWidget(false)} /> */}
                  </div>
                )
              }
              <ChatbotDiv isMobileDevice={isMobileDevice}>
                <div className={isMobileDevice ? 'header-div-mobile' : 'header-div'}>
                  <div className="header-sub-div">
                    <div className="intro-div">I'm Jordy</div>
                    {mode === ChatBotModes.regular ?
                      (
                        <div className="jordy-desc1">Your friendly {jobPackTechnology !== 'generic' ? jobPackTechnology : ''} learning buddy</div>
                      ) : (
                        <div className="jordy-desc1">I am here to help you connect with our experts to solve your queries</div>
                      )
                    }
                  </div>
                </div>
                <Messages
                  messages={messages}
                  isMobileDevice={isMobileDevice}
                  connectWithAssistant={connectWithAssistant}
                  uniqueId={uniqueId}
                  enableProgressiveDisplay={enableProgressiveDisplay}
                />
                <Input
                  onSend={send}
                  waitForHumanResponse={waitForHumanResponse}
                  isConnectedToHuman={isConnectedToHuman}
                  isMobileDevice={isMobileDevice}
                />
              </ChatbotDiv>
            </>
          ) : (
            <div className="chatbot-jordy-icon-left" id="jordyicon">
              <div className="help-query" id="help">Need help?</div>
              <img src="https://doubt-buster-images.s3.ap-south-1.amazonaws.com/jordybackground.png" alt="jordy icon" className="background-jordy-image" onClick={() => handleWidget(true)} />
              <img src="https://doubt-buster-images.s3.ap-south-1.amazonaws.com/jordyminimize.png" alt="jordy icon" className="minimize-image" onClick={() => handleWidget(true)} />
            </div>
          )
      }
    </>
  );
}

export default Chatbot;