import React from 'react';
import { connectWithExpertText } from '../constants';

interface ConnectToHumanProps {
    connectWithAssistant: (connect: boolean, uniqueId: string) => void;
    uniqueId: string;
}

const ConnectToHuman = (props: ConnectToHumanProps) => {
    const { connectWithAssistant, uniqueId } = props;

    return (
        <div className="message-container">
            <div className="message-inner-container">
                <img src="https://doubt-buster-images.s3.ap-south-1.amazonaws.com/jordyminimize.png" alt="chat bot icon" className="jordy-chat-icon" />
                <div className="multiple-messages">
                    <div className="bot-message">
                        <div>{connectWithExpertText}</div>
                        <div className="assistant-accept-block">
                            <div className="accept-button" onClick={() => connectWithAssistant(true, uniqueId)}>Yes</div>
                            <div className="reject-button" onClick={() => connectWithAssistant(false, uniqueId)}>No</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ConnectToHuman;
