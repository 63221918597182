import axios from "axios";
import { useState, useEffect, useContext, useCallback } from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import { tomorrow } from 'react-syntax-highlighter/dist/esm/styles/hljs'
import UserReactionContext from "../context/Reaction/userReactionContext";
import { generateKey } from "../general";
import { checkForFile, getBotDetails } from "../utils"
import ImageTrainer from "./ImageTrainer";
import IntentData from "./IntentData";
import ReactionHover from "./reactionHover";
import UseProgressiveDisplay from "./UseProgressiveDisplay";
import React from "react";
const triggerMessage = "notjordy-at-hcl.com";


const ENDPOINT: any = getBotDetails("server-url") || process.env.REACT_APP_HUMAN_HANDOFF_ENDPOINT;

interface BotMessageProps {
  messages: any;
  isConnectedToHuman: boolean;
  fetchMessage: () => void;
  uniqueKeyMap: any;
  isLastMessage: Boolean;
  callingMes: (item: any) => void;
  isPreviousMessage: Boolean;
  enableProgressiveDisplay?: Boolean;
}

const BotMessage = (props: BotMessageProps) => {
  const { fetchMessage, isConnectedToHuman, uniqueKeyMap, callingMes, messages, isLastMessage, isPreviousMessage, enableProgressiveDisplay} = props;
  const [isLoading, setLoading] = useState(true);
  const [message, setMessage] = useState<any>([]);
  const [triggeredMessage, setTriggeredMessage] = useState('');
  const [hoverState, setHoverState] = useState(false);
  const feedbackMessageType = useContext(UserReactionContext);
  const { setResponseLoading } = useContext(UserReactionContext);

  useEffect(()=>{
    setResponseLoading(isLoading);
  },[isLoading])

  useEffect(() => {
    const loadMessage = async () => {
      let messages: any = await fetchMessage();
      let response;
      if (messages.isLoading) {
        messages = [];
      } else {
        response = messages[0]?.text;
        if (response?.includes(triggerMessage)) {
          setTriggeredMessage(response);
        }
        setLoading(false);
      }
      setMessage(messages);
    }
    loadMessage();
  }, [fetchMessage]);

  const onSubmitHandler = (reactionType:any) => {
    callingMes(uniqueKeyMap);
    const question = messages?.findIndex(({uniqueKey}:any)=>uniqueKey == feedbackMessageType?.mappingId);
   axios({
    method: 'post',
    url: `${ENDPOINT}/v1/userfeedback`,
    data:JSON.stringify({
      "id": feedbackMessageType.mappingId,
      "question": (messages?.length > 0 && messages[question-1]?.message) || 'Initial Message',
      "answer": message[0].text,
      "reactionType":reactionType
    }),
    headers: {
      'Content-Type': 'application/json',
  }
  })

}

const mouseEnterHandler = useCallback((e:any) => {
  setHoverState(true);
}, []);

const mouseLeaveHandler = useCallback((e:any) => {
  setHoverState(false);
}, []);

const enableFeedback = getBotDetails("enableFeedback") || new URLSearchParams(window.location.search).get('enableFeedback') === 'true';
const enableDebugMode=getBotDetails("enableDebugMode") || new URLSearchParams(window.location.search).get('enableDebugMode') === 'true';

  return (
    <div className="message-container" onMouseEnter = {mouseEnterHandler} onMouseLeave={mouseLeaveHandler}>
      {
        !triggeredMessage && (
          <div className="message-inner-container">
            <img src={isConnectedToHuman ? "https://doubt-buster-images.s3.ap-south-1.amazonaws.com/trainer.png" : "https://doubt-buster-images.s3.ap-south-1.amazonaws.com/jordyminimize.png"} alt="chat bot icon" className="jordy-chat-icon" />
            {
              isLoading && (
                <div className="bot-message" style={{ fontFamily: 'Roboto' }}>...</div>
              )
            }
            <div className="multiple-messages">
              {
                message.map((item: any, index:any) => {
                  return item.text ? (
                  checkForFile(item.text) ? (
                  <ImageTrainer text={item.text} keyValue={"id" + generateKey()}/>
                  ): <div key={index} className="bot-message">{isLoading ? "..." : <>{enableProgressiveDisplay ? (
                    <>
                      <UseProgressiveDisplay
                        isLastMessage={isLastMessage}
                        isPreviousMessage={isPreviousMessage}
                        line={item.text.replace(/\\n/g, '\n').replace("My friend DaVinci says:","")}
                      /><br />
                    </>
                  ) : (
                    <>
                      <div>{item.text.replace(/\\n/g, '\n').replace("My friend DaVinci says:","").split('\n').map((line:any, lineIndex:number)=>{
                        return(
                          <React.Fragment key={"id" + generateKey()}>
                          <div>{line}</div>
                          {(!(lineIndex === item.text.replace(/\\n/g, '\n').split('\n').length - 1) && line.length > 0) && <br />}
                          </React.Fragment>
                        )
                      })}</div>
                    </>
                  )}</>}

    {enableDebugMode && item.intentData !== undefined && item.intentData !== null && (
      <IntentData intentDetails={item.intentData} hoverState={hoverState} />
    )}</div>
                ) : (item.custom ?
                  <SyntaxHighlighter
                    language="java"
                    className="code-message"
                    style={tomorrow}
                    wrapLongLines
                  >
                    {isLoading ? "..." : item.custom.code?.replace(/\\n/g, '\n')}
                  </SyntaxHighlighter> :
                  <img src={item.image} alt="Response data" width="250px" />
                )})
              } 
            </div>
          </div>
        )
      }
      {enableFeedback && <ReactionHover onSubmitHandler = {onSubmitHandler} hoverState = {hoverState} />}
    </div>
  );
};

export default BotMessage;
