import { useState } from 'react';
import UserReactionContext from './userReactionContext';


const UserReactionTrackState = (props: any) => {

    const [feedbackMessage, setFeedbackMessage] = useState('');
    const [mappingId, setMappingId] = useState('');
    const [selectMessageId, setselectMessageId] = useState('');
    const [isTextLoading, setTextLoading] = useState(false);
    const [isResponseLoading, setResponseLoading] = useState(true);

return (
    <UserReactionContext.Provider value={{feedbackMessage, setFeedbackMessage, mappingId, setMappingId, selectMessageId, setselectMessageId, isTextLoading, setTextLoading, isResponseLoading, setResponseLoading}}>
        {props.children}
    </UserReactionContext.Provider>
);
};

export default UserReactionTrackState;
