import { useEffect, useState } from 'react';
import './App.css';
import Chatbot from './components/ChatBot';
import UserReactionTrackState from './context/Reaction/userReactionState';
import { getBotDetails } from './utils';

let mode = getBotDetails("mode");

if (!mode) {
  mode = new URL(window.location.href).searchParams.get('mode');
}

let isMobileDevice: any = new URL(window.location.href).searchParams.get('isMobileDevice') || false;
isMobileDevice = isMobileDevice === 'true';
if (mode === null) mode = "Regular";

const App = () => {
const [showWidget, setShowWidget] = useState(false);

const handleWidget = (value: boolean) => {
  setShowWidget(value);
};

useEffect(() => {
  if(isMobileDevice) {
    setShowWidget(true);
  }
}, [])

  return (
    <div className={isMobileDevice ? 'chat-bot-app-mobile' : 'chat-bot-app'}>
      <UserReactionTrackState>
      <Chatbot showWidget={true} handleWidget={handleWidget} mode={mode} isMobileDevice={isMobileDevice} />
      </UserReactionTrackState> 
    </div>
  );
}

export default App;
