
export const connectWithExpertText = 'Shall I connect you to an expert?';

export const connectedWithExpertText = 'Connected to Expert...';

export const connectingToExpertText = 'Connecting to Expert...';

export const allOurExpertsAreBusyWillGetBackToYouSoonText = 'All our Experts are currently busy. We will get back to you soon.';

export const trainerJoinedText = 'joined the chat';

export const trainerLeftTheChat = 'left the chat';

export const byeText = 'bye';

export const allOurExpertsBusyPleaseTryAfterSomeTimeText = 'All our experts are busy right now. Please try after some time.';

export const pleaseContactYourTrainer = 'Currently our experts are not available. Please contact your trainer.';

export const triggerMessageText = "notjordy-at-hcl.com";

export const generateInitialText = (userName: string) => {
    if(userName!==undefined){
        return `Hi ${userName.startsWith('Jordy') ? 'there' : userName}!`
    }
    else{
        return 'Hi'
    }
    
};

export const iCanHelpWithYourRelatedQuestionsText = (jobPack: string) => {
    return `I can help with your ${jobPack !== 'generic' ? jobPack : 'general programming and IT'} related questions`
};
